import $ from 'jquery'
import {
  fenster,
  setVertragspartner,
  closeWindow,
  closeWindowAndUpdateOpener,
  projectInit,
  updateOpener
} from '@/js/project'
import { registerComponents } from '@/js/vue'
import { expandDetails } from '@/js/appearence.js'
import { uiInitTooltips } from '@/js/tooltip'
import { initTopicApp } from '@/js/topic-app'
import { createApp } from 'vue'
import FomanticUI from 'vue-fomantic-ui'
import { RTEAutoconfig } from '@/js/rte.js'
import { configureFormBlock } from '@/js/formblock.js'
import bindFechContent from '@/js/fetch-content.js'
import '@/js/unit-form.js'
import '@/js/pop.js'

export function uiInit () {
  registerComponents()

  $('.addon').hide()
  $('.select2').select2()

  $('textarea').each(function () {
    autoresize(this)
  }).on('input', function () {
    autoresize(this)
  })

  $.fn.datepicker.defaults.language = 'de'
  $.fn.datepicker.defaults.format = 'dd.mm.yyyy'
  $.fn.datepicker.defaults.weekStart = 1

  for (const el of document.getElementsByClassName('expand-details')) {
    el.addEventListener('click', expandDetails)
  }
  projectInit()
  uiInitTooltips()

  const rte = new RTEAutoconfig()
  rte.find()

  configureFormBlock()
  bindFechContent()

  globalThis.topicApp = initTopicApp()
}

export function initializeWindow (window) {
  window.$ = window.jQuery = $
  window.Vue = createApp({})

  window.Vue.use(FomanticUI)

  window.fenster = fenster
  window.closeWindow = closeWindow
  window.updateOpener = updateOpener
  window.closeWindowAndUpdateOpener = closeWindowAndUpdateOpener
  window.setVertragspartner = setVertragspartner
}

function autoresize (textarea) {
  textarea.style.height = '0px' // Reset height, so that it not only grows but also shrinks
  textarea.style.height = (textarea.scrollHeight + 10) + 'px' // Set new height
}
